import React from "react";
import PropTypes from "prop-types";
import styles from "./ConfirmServiceModal.module.css";

const ConfirmServiceModal = ({ onClose, onCancel, onConfirm }) => {
  const handleClose = () => {
    console.log("Action: Close overlay");
    onClose();
  };

  const handleCancel = () => {
    console.log("State=Cancel; Action: Close overlay");
    onCancel();
  };

  const handleConfirm = () => {
    console.log("State=Default; Action: Close overlay");
    onConfirm();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button
          className={styles.closeButton}
          onClick={handleClose}
          aria-label="Close"
        >
          &times;
        </button>
        <h2 className={styles.modalTitle}>Confirm Service Initiation</h2>
        <p className={styles.modalDescription}>
          Once confirmed, your selected services will be initiated and
          associated costs will be incurred. These charges are non-refundable.
        </p>
        <div className={styles.buttonGroup}>
          <button
            className={styles.cancelButton}
            onClick={handleCancel}
            aria-label="Cancel"
          >
            Cancel
          </button>
          <button
            className={styles.confirmButton}
            onClick={handleConfirm}
            aria-label="Confirm"
          >
            Confirm and Initiate Services
          </button>
        </div>
      </div>
    </div>
  );
};

// ✅ Ensure the props always exist
ConfirmServiceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

// ✅ Provide default empty functions to prevent crashes
ConfirmServiceModal.defaultProps = {
  onCancel: () => console.log("Default Cancel Action"),
  onConfirm: () => console.log("Default Confirm Action"),
};

export default ConfirmServiceModal;
