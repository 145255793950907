import React from "react";
import PropTypes from "prop-types";
import styles from "./StudentListTableHeader.module.css";
import InformationPopup from "components/InformationPopup";

const StudentListTableHeader = ({ isChecked, onSelectAll }) => {
  return (
    <div className={styles.container}>
      <div className={styles.checkboxContainer}>
        <div className={styles.tooltipWrapper}>
          <input
            type="checkbox"
            className={`${styles.checkbox} ${isChecked ? styles.checked : ""}`}
            onChange={(e) => onSelectAll(e.target.checked)}
            checked={isChecked}
          />
        </div>
      </div>
      <div className={styles.studentNameContainer}>
        <span className={styles.studentNameText}>Student Name</span>
      </div>
      <div className={styles.firstCategoryHeaderContainer}>
        <span className={styles.firstCategoryHeaderText}>
          Checkr Background Check
        </span>
      </div>
      <div className={styles.secondCategoryHeaderContainer}>
        <span className={styles.secondCategoryHeaderText}>
          CPNW WATCH Report
        </span>
      </div>
      <div className={styles.statusContainer}>
        <span className={styles.statusText}>Status</span>
      </div>
    </div>
  );
};

StudentListTableHeader.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onSelectAll: PropTypes.func.isRequired,
};

export default StudentListTableHeader;
