import React from "react";
import styles from "./StatusButton.module.css";

const StatusButton = ({ onClick, status }) => {
  let buttonClass = "";
  let buttonText = "";

  switch (status) {
    case "initiate":
      buttonClass = styles.initiate;
      buttonText = "Initiate";
      break;
    case "sent":
      buttonClass = styles.sent;
      buttonText = "Sent";
      break;
    case "na":
      buttonClass = styles.na;
      buttonText = "N/A";
      break;
    default:
      buttonClass = styles.default;
      buttonText = "N/A";
      break;
  }

  return (
    <button
      className={`${styles.button} ${buttonClass}`}
      onClick={onClick}
      disabled={status === "na"}
    >
      {buttonText}
    </button>
  );
};

export default StatusButton;
