import React from "react";
import PropTypes from "prop-types";
import StudentListItem from "../StudentListItem/StudentListItem";
import styles from "./StudentList.module.css";

const StudentList = ({
  students,
  selectedStudents,
  onSelectStudent,
  onInitiate,
  radioSelections,
  onRadioSelection,
  statuses,
}) => {
  return (
    <div className="scroll-wrapper">
      <div className={styles.container}>
        {students.map((student, index) => (
          <React.Fragment key={index}>
            <StudentListItem
              studentName={student.name}
              isChecked={selectedStudents[index]}
              onCheckboxChange={(isChecked) =>
                onSelectStudent(index, isChecked)
              }
              onInitiate={() => onInitiate(index)}
              onRadioSelection={() => onRadioSelection(index)}
              isRadioSelected={radioSelections[index]}
              status={statuses[index]} // Pass the status prop
            />
            {index < students.length - 1 && <div className={styles.divider} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

StudentList.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedStudents: PropTypes.arrayOf(PropTypes.bool).isRequired,
  onSelectStudent: PropTypes.func.isRequired,
  onInitiate: PropTypes.func.isRequired,
  radioSelections: PropTypes.arrayOf(PropTypes.bool).isRequired,
  onRadioSelection: PropTypes.func.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default StudentList;
