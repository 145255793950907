import React, { useState } from "react";
import PropTypes from "prop-types";
import StudentList from "../StudentList/StudentList";
import StudentListTableHeader from "../StudentListTableHeader/StudentListTableHeader";
import styles from "./StudentListTable.module.css";

const StudentListTable = ({ students, onInitiate }) => {
  const [selectedStudents, setSelectedStudents] = useState(
    students.map(() => false)
  );
  const [radioSelections, setRadioSelections] = useState(
    students.map(() => false)
  );
  const [statuses, setStatuses] = useState(
    students.map(() => "na") // Initialize all students as "N/A"
  );

  const handleSelectAll = (isChecked) => {
    setSelectedStudents(students.map(() => isChecked));
  };

  const handleSelectStudent = (index, isChecked) => {
    const updatedSelection = [...selectedStudents];
    updatedSelection[index] = isChecked;
    setSelectedStudents(updatedSelection);
  };

  const handleRadioSelection = (index) => {
    const updatedSelections = [...radioSelections];
    updatedSelections[index] = true; // Mark that a radio button has been selected
    setRadioSelections(updatedSelections);

    const updatedStatuses = [...statuses];
    updatedStatuses[index] = "initiate"; // Change status to "Initiate"
    setStatuses(updatedStatuses);
  };

  const handleConfirmInitiation = (index) => {
    const updatedStatuses = [...statuses];
    updatedStatuses[index] = "sent"; // Change status to "Sent"
    setStatuses(updatedStatuses);
  };

  return (
    <div className={styles.container}>
      <StudentListTableHeader
        isChecked={selectedStudents.every((isSelected) => isSelected)}
        onSelectAll={handleSelectAll}
      />
      <div className={styles.listContainer}>
        <StudentList
          students={students}
          selectedStudents={selectedStudents}
          onSelectStudent={handleSelectStudent}
          onInitiate={onInitiate}
          radioSelections={radioSelections} // Pass selection state
          onRadioSelection={handleRadioSelection}
          statuses={statuses} // Pass statuses state
          onConfirmInitiation={handleConfirmInitiation} // Update status to "Sent"
        />
      </div>
    </div>
  );
};

StudentListTable.propTypes = {
  students: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onInitiate: PropTypes.func.isRequired,
};

export default StudentListTable;
