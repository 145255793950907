import React, { useState } from "react";
import PropTypes from "prop-types";
import StatusButton from "components/StatusButton";
import styles from "./StudentListItem.module.css";

const StudentListItem = ({
  studentName,
  onInitiate,
  isChecked,
  onCheckboxChange,
  onRadioSelection,
  isRadioSelected,
  status,
}) => {
  const [selected, setSelected] = useState({ first: false, second: false });

  const handleRadioClick = (key) => {
    const newSelection = { ...selected, [key]: !selected[key] };
    setSelected(newSelection);

    if (!isRadioSelected && !selected[key]) {
      onRadioSelection(); // Notify parent that a radio was selected
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          className={`${styles.checkbox} ${isChecked ? styles.checked : ""}`}
          onChange={(e) => onCheckboxChange(e.target.checked)}
          checked={isChecked}
        />
      </div>

      <div className={styles.studentNameContainer}>
        <span className={styles.studentNameText}>{studentName}</span>
      </div>

      <div className={styles.radioButtonContainer}>
        <input
          type="checkbox"
          className={`${styles.radioButton} ${
            selected.first ? styles.checked : ""
          }`}
          onChange={() => handleRadioClick("first")}
          checked={selected.first}
        />
      </div>

      <div className={styles.radioButtonContainer}>
        <input
          type="checkbox"
          className={`${styles.radioButton} ${
            selected.second ? styles.checked : ""
          }`}
          onChange={() => handleRadioClick("second")}
          checked={selected.second}
        />
      </div>

      <div className={styles.statusContainer}>
        <StatusButton onClick={onInitiate} status={status} />
      </div>
    </div>
  );
};

StudentListItem.propTypes = {
  studentName: PropTypes.string.isRequired,
  onInitiate: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onRadioSelection: PropTypes.func.isRequired,
  isRadioSelected: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired, // Pass the status prop
};

export default StudentListItem;
