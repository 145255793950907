import { useEffect, useState } from "react";
import { Loading } from "@cpnw/ui";
import { AdminContainer } from "hooks/useAdmin";
import StudentListTable from "components/StudentList/StudentListTable";
import DashboardSearch from "components/DashboardSearch";
import ConfirmServiceModal from "components/ConfirmServiceModal";
import styles from "./BackgroundWatch.module.css";
import { HeaderSection } from "@cpnw/ui";

const BackgroundWatch = () => {
  const { refetchAdminUsers } = AdminContainer.useContainer();
  const [loading, setLoading] = useState(true);

  // Search states
  const [searchStudent, setSearchStudent] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchProgram, setSearchProgram] = useState("");
  const [searchAnything, setSearchAnything] = useState("");

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudentIndex, setSelectedStudentIndex] = useState(null);

  // Track radio button selection per student
  const [studentSelections, setStudentSelections] = useState(
    Array(7).fill(false)
  );

  // Track status per student
  const [studentStatuses, setStudentStatuses] = useState(
    Array(7).fill("na") // Default status is "N/A"
  );

  useEffect(() => {
    console.log("BackgroundWatch Component Loaded");
    if (refetchAdminUsers) {
      refetchAdminUsers().finally(() => setLoading(false));
    } else {
      setTimeout(() => setLoading(false), 2000);
    }
  }, []);

  const students = [
    { name: "Doe, Jane" },
    { name: "Nanchenkov, Alexander" },
    { name: "Pines, Gregory" },
    { name: "Omot, Chloe" },
    { name: "Nancarrow, Sebastian" },
    { name: "Wiseman, Dylan" },
    { name: "Mulubari, Adukunyo" },
  ];

  // Function to handle radio button selection
  const handleRadioSelection = (index) => {
    setStudentSelections((prev) =>
      prev.map((selected, i) => (i === index ? !selected : selected))
    );

    setStudentStatuses((prev) =>
      prev.map((status, i) => (i === index ? "initiate" : status))
    );
  };

  // Open modal and store selected student index
  const handleInitiate = (index) => {
    setSelectedStudentIndex(index);
    setIsModalOpen(true);
  };

  // Confirm service and update student status
  const handleConfirmService = () => {
    setStudentStatuses((prevStatuses) =>
      prevStatuses.map((status, index) =>
        index === selectedStudentIndex ? "sent" : status
      )
    );
    setIsModalOpen(false);
  };
  const [statuses, setStatuses] = useState(
    students.map(() => "na") // Initialize all students as "N/A"
  );

  const handleConfirmInitiation = () => {
    if (selectedStudentIndex !== null) {
      const updatedStatuses = [...statuses];
      updatedStatuses[selectedStudentIndex] = "sent"; // Update status to "Sent"
      setStatuses(updatedStatuses);
      setSelectedStudentIndex(null);
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <HeaderSection header={"Background + WATCH"} />

      {/* Search Container */}
      <div className={styles.searchContainer}>
        <DashboardSearch
          onInput={setSearchStudent}
          showLabel={false}
          placeholder="Search Student"
        />
        <DashboardSearch
          onInput={setSearchStatus}
          showLabel={false}
          placeholder="Search Status"
        />
        <DashboardSearch
          onInput={setSearchProgram}
          showLabel={false}
          placeholder="Search Program"
        />
        <DashboardSearch
          onInput={setSearchAnything}
          showLabel={false}
          placeholder="Search Anything"
        />
      </div>

      {/* Student Table */}
      <div className={styles.table}>
        <StudentListTable
          students={students}
          studentStatuses={studentStatuses}
          onInitiate={handleInitiate}
          onRadioSelection={handleRadioSelection}
        />
      </div>

      {/* Confirm Service Modal */}
      {isModalOpen && (
        <ConfirmServiceModal
          onClose={() => setIsModalOpen(false)}
          onCancel={() => {
            console.log("Cancelled service initiation");
            setIsModalOpen(false);
          }}
          onConfirm={() => {
            console.log("Service initiated successfully!");
            setIsModalOpen(false);
            handleConfirmInitiation(); // Change status to "sent"
          }}
        />
      )}
    </>
  );
};

export default BackgroundWatch;
