import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./DashboardSearch.module.css";

export default function DashboardSearch({
  onInput,
  searchDelay = 300,
  minChar = 1,
  showLabel = true,
  placeholder = "Search...",
}) {
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search.length < minChar) {
      onInput("");
      return;
    }
    if (searchDelay) {
      const timeoutId = setTimeout(() => onInput(search), searchDelay);
      return () => clearTimeout(timeoutId);
    } else {
      onInput(search);
    }
  }, [search]);

  return (
    <div className={styles.dash_search_row}>
      {showLabel && (
        <label htmlFor="dash-search" className={styles.dash_search_label}>
          Search:
        </label>
      )}
      <input
        type="text"
        id="dash-search"
        className={styles.dash_search_input}
        placeholder={placeholder}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
}

DashboardSearch.propTypes = {
  onInput: PropTypes.func,
  searchDelay: PropTypes.number,
  minChar: PropTypes.number,
  showLabel: PropTypes.bool,
  placeholder: PropTypes.string,
};
